import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import PropTypes from 'prop-types';


class ThankYouPage extends Component {

	componentDidMount() {
	}




	render() {

		const content = this.props.data.allContentfulAccueil.edges[0].node;

		return(
		<Layout>
		<SEO title="Merci" description={content.metaDescription} keywords={content.metaKeywords} />
	
		<section className="module-header full-height jarallax bg-dark bg-dark-30 text-left" id="home" data-jarallax>
			<img className="jarallax-img" src={content.imageThankYou.fluid.src} srcSet={content.imageThankYou.fluid.srcSet} sizes={content.imageThankYou.fluid.sizes} alt=""/>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 className="h1 m-b-15">Merci</h1>
						<h1 className="h6 m-b-60">Nous vous répondrons rapidement</h1>
						<p><Link className="btn btn-brand" to="/"><span>Retour à la page d'accueil</span></Link></p>
					</div>
				</div>
			</div>
		</section>

		
	  </Layout>)
	}
}




ThankYouPage.propTypes = {
	data: PropTypes.object.isRequired,
	
  }

export default ThankYouPage

export const staticQuery = graphql`
  query {
    allContentfulAccueil (limit:1) {
      edges {
        node {
          id

		  imageThankYou {
			title
			fluid(maxWidth: 1920, resizingBehavior: FILL) {
				sizes
				src
				srcSet
				aspectRatio
			}
		  }


		  metaTitle
		  metaDescription
		  metaKeywords
        }
      }
    }
  }
`